/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import img1 from "../../screens/Homepage/Image/Cafe.png";
import img2 from "../../screens/Homepage/Image/Cafe1.png";
import img3 from "../../screens/Homepage/Image/Eat.png";
import img4 from "../../screens/Homepage/Image/Eat1.png";
import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Hills } from "../Hills";
import "./style.css";

export const CardSlide = ({
  property1,
  className,
  rectangle = "https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-28-12.svg",
  text = "Bullet point",
  text3,
  text4,
  text5,
  hasBulletPoint = true,
  hasDiv = true,
  override = <Hills className="hills-1" />,
  ingClassName,
  ingClassName2,
  ingClassName3,
  text1 = "Tour trecking",
  text2 = "Hành trình đưa chúng ta đến gần hơn với cảm giác tự do cùng mẹ thiên nhiên",
  visible = true,
  cOntainerClassName,
  img = "https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-28-12.svg",
  rectangle1 = "https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-28-12.svg",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });
  const images = [img1, img2, img3, img4];
  return (
    <div
      className={`card ${state.property1} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
    >
      <div className={`c-ontainer ${cOntainerClassName}`}>
        <div className="div">
          <div className="frame-2">
            <div className="tour-trecking">{text1}</div>
            <p className="h-nh-tr-nh-a-ch-ng">{text2}</p>
            {visible && (
              <div className="bullet-point">
                <img className="rectangle" alt="Rectangle" src={rectangle} />
                <div className="text-wrapper">{text}</div>
              </div>
            )}

            {hasBulletPoint && (
              <div className="bullet-point">
                <img className="rectangle" alt="Rectangle" src={img} />
                <div className="bullet-point-2">{text3}</div>
              </div>
            )}

            {hasDiv && (
              <div className="bullet-point">
                <img className="rectangle" alt="Rectangle" src={rectangle1} />
                <div className="bullet-point-3">{text4}</div>
              </div>
            )}

            <div className="frame-3">
              {/* <div className="text-wrapper-2">Đặt chỗ ngay</div> */}
              <a href="https://m.me/106563055677190" className="text-wrapper-2">
                Đặt chỗ ngay
              </a>
              <img
                className="arrow-forward"
                alt="Arrow forward"
                src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/arrow-forward-9@2x.png"
              />
            </div>
          </div>
          {override}
        </div>
        {/* <div className={`ing ${ingClassName}`} /> */}
        {/* <div className="ing">
          <AliceCarousel autoPlay autoPlayInterval="3000">
            <img src={`slider ${ingClassName}`} alt="" />
            <img src={`slider ${ingClassName2}`} alt="" />
            <img src={`slider ${ingClassName3}`} alt="" />
          </AliceCarousel>
        </div> */}
        <div className="ing">
          <AliceCarousel autoPlay autoPlayInterval="3000">
            <div className={`slider ${ingClassName}`} />
            <div className={`slider ${ingClassName2}`} />
            <div className={`slider ${ingClassName3}`} />
          </AliceCarousel>
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }

  return state;
}

CardSlide.propTypes = {
  property1: PropTypes.oneOf(["hover", "default"]),
  rectangle: PropTypes.string,
  text: PropTypes.string,
  hasBulletPoint: PropTypes.bool,
  hasDiv: PropTypes.bool,
  text1: PropTypes.string,
  text2: PropTypes.string,
  visible: PropTypes.bool,
  img: PropTypes.string,
  rectangle1: PropTypes.string,
};
