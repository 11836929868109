import React, { useEffect, useState } from "react";
import { Resizable } from "react-resizable";
import { CardSlide } from "../../components/CardSlide";
import { useRef } from "react";
import { Bagpack } from "../../components/Bagpack";
import { Accordians } from "../../components/Accordians";
import { Bullet } from "../../components/Bullet";
import { Car } from "../../components/Car";
import { Card } from "../../components/Card";
import { Component } from "../../components/Component";
import { Frame } from "../../components/Frame";
import { Hiking } from "../../components/Hiking";
import { Logo } from "../../components/Logo";
import { RiceBowl } from "../../components/RiceBowl";
import { Tea } from "../../components/Tea";
import { Testimonials } from "../../components/Testimonials";
import { Camp1 } from "../../icons/Camp1";
import { Coffee1 } from "../../icons/Coffee1";
import { FacebookNegative } from "../../icons/FacebookNegative";
import { Frame963 } from "../../icons/Frame963";
import { Frame965 } from "../../icons/Frame965";
import { Frame96 } from "../../icons/Frame96";
import { PhotoCamera17 } from "../../icons/PhotoCamera17";
import { ToDoList } from "../../icons/ToDoList";
import { TiktokNegative } from "../../icons/TiktokNegative";
import { X3138 } from "../../icons/X3138";
import { Ointment1 } from "../../icons/Ointment1";
import { Primer1 } from "../../icons/Primer1";
import { Scarf1 } from "../../icons/Scarf1";
import { Sunblock1 } from "../../icons/Sunblock1";
import { CaptainCap1 } from "../../icons/CaptainCap1";
// import Fade from "react-reveal/Fade";
import { Fade } from "react-slideshow-image";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Bg1 from "../../screens/Homepage/Image/header1.png";
import Bg2 from "../../screens/Homepage/Image/header2.png";
import Bg3 from "../../screens/Homepage/Image/header3.png";
import Bg4 from "../../screens/Homepage/Image/header4.png";
import img1 from "../../screens/Homepage/Image/Cafe.png";
import img2 from "../../screens/Homepage/Image/Cafe1.png";
import img3 from "../../screens/Homepage/Image/Eat.png";
import img4 from "../../screens/Homepage/Image/Eat1.png";
import GoogleMapReact from 'google-map-react';
import Iframe from 'react-iframe'
// import "react-slideshow-image/dist/styles.css";
import "./style.css";
export const Homepage = () => {
  const images = [Bg1, Bg2, Bg3, Bg4];
  const ServiceImg = [img1, img2, img3, img4];
  const { Resizable } = require("react-resizable");

  const ServiceRef = useRef(null);
  const FAQRef = useRef(null);
  const ActivityRef = useRef(null);
  const MapRef = useRef(null);
  const PriceRef = useRef(null);

  const priceClick = () => {
    PriceRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const serviceClick = () => {
    ServiceRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const faqClick = () => {
    FAQRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const activityClick = () => {
    ActivityRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const mapClick = () => {
    MapRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const defaultProps = {
    center: {
      lat: 11.58187531151686,
      lng: 108.05234094232785
    },
    zoom: 15
  };

  return (
    <Resizable
      width={200}
      height={200}
      draggableOpts={{ grid: [25, 25] }}
      minConstraints={[100, 100]}
      maxConstraints={[300, 300]}
    >
      <div className="homepage">
        <div className="div-4">
          <div className="frame-9">
            {/* <div className="event_poster">
              <img src="https://c.animaapp.com/AljjMxgp/img/z4796245300437-7347bcb9988f2895afb7fd7f8021ccc9-1.png" alt="Poster"/>
              <div className="register_btn">
                <a className="btn--outline" href="https://forms.gle/bVyQiiP2GwDFAWnp9"><p>ĐĂNG KÝ NGAY</p></a>
              </div>
	
            </div> */}
            {/* <Fade> */}
            <div ref={ServiceRef} className="section">
              <p className="d-ch-v-h-p-d-n-t-i">
                Dịch vụ hấp dẫn tại <br />
                Kiss House
              </p>
              <div className="frame-10">
                <div className="frame-11">
                  <CardSlide
                    cOntainerClassName="card-5"
                    className="card-6"
                    hasBulletPoint={false}
                    hasDiv={false}
                    ingClassName="card-7"
                    ingClassName2="card-7-1"
                    ingClassName3="card-7-2"
                    override={<Tea className="tea-instance" />}
                    property1="default"
                    text1="Cafe"
                    text2="Tận hưởng cafe bên bờ hồ, đắm chìm vào không gian thư giãn rời xa những ồn ào khói bụi thành phố vào mỗi cuối tuần, dịp lễ."
                    visible={false}
                  />
                  <Card
                    className="card-instance"
                    img="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-28-2.svg"
                    ingClassName="card-8"
                    override={<Car className="car-instance" />}
                    property1="default"
                    rectangle="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-28-2.svg"
                    rectangle1="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-28-2.svg"
                    text="Trang trí tiệc cưới, lễ kỉ niệm"
                    text3="Tổ chức sinh nhật"
                    text4="Workshop"
                    text1="Tổ chức sự kiện"
                    text2="Với không gian rộng rãi, thoáng đãng phù hợp tổ chức các sự kiện, các bữa tiệc thân mật theo từng nhu cầu khác nhau của bạn."
                  />

                  <Card
                    className="card-3"
                    hasBulletPoint={false}
                    hasDiv={false}
                    ingClassName="card-4"
                    override={<RiceBowl className="rice-bowl-instance" />}
                    property1="default"
                    text1="Team building"
                    text2="Liên hệ với chúng tôi"
                    visible={false}
                  />
                </div>
                <div className="frame-11">
                  <CardSlide
                    className="card-instance"
                    ingClassName="card-2"
                    ingClassName2="card-2-1"
                    ingClassName3="card-2-2"
                    override={<RiceBowl className="rice-bowl-instance" />}
                    property1="default"
                    rectangle="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-28-12.svg"
                    text="BBQ đồ nướng"
                    text3="Lẩu giành cho nhóm"
                    text4="...."
                    text1="Ăn uống"
                    text2="Bộ menu đa dạng tuỳ theo mùa và từng thời điểm trong năm"
                  />
                  <Card
                    className="card-instance"
                    hasBulletPoint={false}
                    hasDiv={false}
                    ingClassName="design-component-instance-node"
                    override={<Hiking className="hiking-1" />}
                    property1="default"
                    rectangle="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-28-6.svg"
                    text="Nhóm &gt; 10 người"
                  />
                </div>
              </div>
            </div>
            <div ref={PriceRef} className="section-2">
              <p className="p">Camping chill cùng gia đình</p>
              <div className="frame-12">
                <div className="frame-13">
                  <div className="frame-14">
                    <div className="frame-15">
                      <div className="text-wrapper-7">Lều đơn</div>
                      <p className="text-wrapper-8">
                        Thích hợp cho các cặp đôi
                      </p>
                      <p className="text-wrapper-8">
                        Đã bao gồm ăn sáng và cafe sáng
                      </p>
                    </div>
                    <div className="frame-16">
                      <div className="frame-17">
                        <img
                          className="img-2"
                          alt="Camping"
                          src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/camping-1@2x.png"
                        />
                        <div className="text-wrapper-9">9 m²</div>
                      </div>
                      <img
                        className="line"
                        alt="Line"
                        src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/line-1-1.svg"
                      />
                      <div className="frame-17">
                        <img
                          className="img-2"
                          alt="Diversity"
                          src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/diversity-1-1@2x.png"
                        />
                        <div className="text-wrapper-9">1-2 người</div>
                      </div>
                    </div>
                  </div>
                  <Fade
                    nextArrow={
                      <button
                        style={{
                          backgroundSize: "cover",
                          border: "0px",
                          width: "0px",
                        }}
                      >
                        <svg
                          fill="#fff"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
                        </svg>
                      </button>
                    }
                    prevArrow={
                      <button
                        style={{
                          backgroundSize: "cover",
                          border: "0px",
                          width: "0px",
                        }}
                      >
                        <svg
                          fill="#fff"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
                        </svg>
                      </button>
                    }
                  >
                    <div className="leu-lon-a-chinh">
                      <div>
                        <img
                          alt="Leu lon a chinh"
                          src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/le--u-lo--n--a--chi-nh-1.png"
                        />
                      </div>
                    </div>
                    <div className="leu-lon-a-chinh">
                      <div>
                        <img
                          alt="Leu lon a chinh"
                          src="https://c.animaapp.com/AlRi5IfN/img/le--u-lo--n--a--chi-nh.png"
                        />
                      </div>
                    </div>
                  </Fade>
                  <Frame
                    className="frame-22-01"
                    property1="default"
                    text="Lều 1-2 người"
                    text1="800.000 VND"
                  />
                  <div className="frame-18">
                    {/* <div className="text-wrapper-10">Đặt chỗ ngay</div> */}
                    <a
                      href="https://m.me/106563055677190"
                      className="text-wrapper-10"
                    >
                      Đặt chỗ ngay
                    </a>
                    <img
                      className="arrow-forward-2"
                      alt="Arrow forward"
                      src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/arrow-forward-2@2x.png"
                    />
                  </div>
                </div>
                <div className="frame-13">
                  <div className="frame-14">
                    <div className="frame-15">
                      <div className="text-wrapper-7">Lều gia đình</div>
                      <p className="text-wrapper-8">
                        Thích hợp cho gia đình, các nhóm bạn
                      </p>
                    </div>
                    <div className="frame-16">
                      <div className="frame-17">
                        <img
                          className="img-2"
                          alt="Camping"
                          src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/camping-1@2x.png"
                        />
                        <div className="text-wrapper-9">16 m²</div>
                      </div>
                      <img
                        className="line"
                        alt="Line"
                        src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/line-1-1.svg"
                      />
                      <div className="frame-17">
                        <img
                          className="img-2"
                          alt="Diversity"
                          src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/diversity-1-1@2x.png"
                        />
                        <div className="text-wrapper-9">&gt;3 người</div>
                      </div>
                    </div>
                  </div>
                  <Fade
                    nextArrow={
                      <button
                        style={{
                          backgroundSize: "cover",
                          border: "0px",
                          width: "0px",
                        }}
                      >
                        <svg
                          fill="#fff"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
                        </svg>
                      </button>
                    }
                    prevArrow={
                      <button
                        style={{
                          backgroundSize: "cover",
                          border: "0px",
                          width: "0px",
                        }}
                      >
                        <svg
                          fill="#fff"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
                        </svg>
                      </button>
                    }
                  >
                    <div className="leu-lon-a-chinh-2">
                      <div>
                        <img
                          alt="Leu lon a chinh"
                          src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/le--u-lo--n--a--chi-nh.png"
                        />
                      </div>
                    </div>
                    <div className="leu-lon-a-chinh-2">
                      <div>
                        <img
                          alt="Leu lon a chinh"
                          src="https://c.animaapp.com/A0pzM1dq/img/le--u-lo--n--a--chi-nh.png"
                        />
                      </div>
                    </div>
                  </Fade>
                  <Frame
                    className="frame-22"
                    property1="default"
                    text="Lều nội khu"
                    text1="1.000.000 VND"
                  />
                  <Frame
                    className="design-component-instance-node-3"
                    property1="default"
                    text="Lều View Hồ Tây"
                    text1="1.100.000 VND"
                  />
                  <div className="frame-19">
                    {/* <div className="text-wrapper-10">Đặt chỗ ngay</div> */}
                    <a
                      href="https://m.me/106563055677190"
                      className="text-wrapper-10"
                    >
                      Đặt chỗ ngay
                    </a>
                    <img
                      className="arrow-forward-2"
                      alt="Arrow forward"
                      src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/arrow-forward-2@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-wrapper">
              <div className="frame-20">
                <div className="frame-21">
                  <div className="div-wrapper">
                    <p className="text-wrapper-11-1">Bạn lo lắng về chi phí?</p>
                    <p className="text-wrapper-11">Chúng tôi có combo trọn gói cho bạn</p>
                  </div>
                  <p className="text-wrapper-12">
                    Nếu bạn đi nhiều thành viên, muốn cắm trại bên hồ, hoà mình với thiên nhiên để có những phút giây rời xa thành thị bên người thân
                  </p>
                </div>
                <div className="frame-23">
                  <div className="frame-24">
                    <div className="frame-25">
                      <Coffee1 className="icon-instance-node" color="#D3FF9A" />
                      <p className="text-wrapper-13">
                        Phần ăn sáng + 1 phần nước uống theo menu cafe
                      </p>
                    </div>
                    <div className="frame-25">
                      <img
                        className="icon-instance-node"
                        alt="Bbq grill"
                        src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/bbq-grill-2.svg"
                      />
                      <p className="text-wrapper-13">
                        Set BBQ ( bò mỹ, tôm tươi, ba chỉ, bò cuộn, mỳ ý, khoai
                        tây chiên,...)
                      </p>
                    </div>
                    <div className="frame-25">
                      <img
                        className="icon-instance-node"
                        alt="Speaker"
                        src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/speaker-1.svg"
                      />
                      <p className="text-wrapper-13">
                        2 giờ sử dụng loa để chill cùng đồng đội ( dành cho nhóm
                        &gt;5 người)
                      </p>
                    </div>
                    <div className="frame-25">
                      <img
                        className="icon-instance-node"
                        alt="Potatoes"
                        src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/potatoes-1.svg"
                      />
                      <p className="text-wrapper-13">
                        Set bắp+ khoai tự nướng ngắm hồ về đêm
                      </p>
                    </div>
                  </div>
                  <div className="frame-24">
                    <div className="frame-25">
                      <img
                        className="group"
                        alt="Group"
                        src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/group@2x.png"
                      />
                      <div className="text-wrapper-13">Đốt lửa trại</div>
                    </div>
                    <div className="frame-25">
                      <Camp1 className="icon-instance-node" />
                      <p className="text-wrapper-13">
                        Set lều với đầy đủ tiện nghi
                      </p>
                    </div>
                    <div className="frame-25">
                      <ToDoList className="icon-instance-node" />
                      <div className="text-wrapper-13">Lịch trình phù hợp</div>
                    </div>
                  </div>
                </div>
                <div className="frame-26">
                  {/* <div className="text-wrapper-10">Đặt chỗ ngay</div> */}
                  <a
                    href="https://m.me/106563055677190"
                    className="text-wrapper-10"
                  >
                    Đặt chỗ ngay
                  </a>
                  <img
                    className="arrow-forward-2"
                    alt="Arrow forward"
                    src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/arrow-forward@2x.png"
                  />
                </div>
              </div>
            </div>
            <div ref={ActivityRef} className="section">
              <p className="p">Những điều nho nhỏ khác</p>
              <div className="frame-27">
                <Component
                  activityClassName="component-instance"
                  className="component-1"
                  text="Đốt lửa trại"
                />
                <Component
                  activityClassName="component-instance"
                  className="component-1-instance"
                  text="Bãi đỗ xe rộng"
                />
                <Component
                  activityClassName="component-instance"
                  className="component-2"
                  text="Đa dạng địa điểm check-in"
                />
                <Component
                  activityClassName="component-instance"
                  className="component-3"
                  text="Khu vui chơi cho bé"
                />
              </div>
            </div>
            <div className="section">
              <p className="p">
                Khách hàng nói về chúng tôi
              </p>
              <div className="frame-28">
                <div className="frame-29">
                  <Testimonials
                    divClassName="testimonials-instance"
                    divClassNameOverride="testimonials-instance"
                    text="Thomas Potter"
                  />
                  <Testimonials
                    divClassName="testimonials-instance"
                    divClassNameOverride="testimonials-instance"
                    ellipse="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ellipse-14-6@2x.png"
                    text="Long Nguyen"
                    text1="Delicious cf, various drink, good place for camping, photograph, beautiful lake view"
                  />
                  <Testimonials
                    divClassName="testimonials-instance"
                    divClassNameOverride="testimonials-instance"
                    ellipse="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ellipse-14-5@2x.png"
                    text="Tuan FANUC"
                    text1="Quán cafe kết hợp cắm trại, lưu trú, sự kiện . Điểm đến checkin Hồ Tây cho các Gia đình ghé Di Linh du lịch.Bạn Nữ chủ quán rất trẻ &amp; tài năng, đồng hương Daklak với mình 🙂"
                  />
                  <Testimonials
                    divClassName="testimonials-instance"
                    divClassNameOverride="testimonials-instance"
                    ellipse="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ellipse-14-4@2x.png"
                    star="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/star-5-4.svg"
                    starClassName="testimonials-2"
                    text="Khánh Quốc"
                    text1="Quán khá ok nha mn. Đường dễ đi mà hơi bụi xíu . View hồ nên thoáng và mát. Không gian rộng rãi. Có bán bún ăn sáng cũng khá ngon.Giá dao động khoảng 30-40k/phần nước."
                  />
                  <Testimonials
                    divClassName="testimonials-instance"
                    divClassNameOverride="testimonials-instance"
                    ellipse="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ellipse-14-3@2x.png"
                    star="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/star-1-8.svg"
                    text="Duc Cao"
                    text1="Một điểm đến khá thú vị cho các gia đình, team thích trải nghiệm glamping. Không khí ở Di Linh (va o Kisss House) mình cảm thấy rất thích, mát và không khí rất trong lành. Bạn chủ set-up khu camping / glamping này rất tỉ mỉ và tâm huyết. Chúc Kiss House thành công!!!"
                  />
                  <Testimonials
                    divClassName="testimonials-instance"
                    divClassName1="testimonials-3"
                    divClassNameOverride="testimonials-instance"
                    ellipse="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ellipse-14-2@2x.png"
                    star="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/star-1-8.svg"
                    text="Tam Phan Thị Thanh"
                    text1="View siêu xịn, nhiều cảnh check in chill, thích hợp cho Gia đình, nhóm bạn, cặp đôi thưởng thức không khí và phong cảnh Tây Hồ."
                  />
                  <Testimonials
                    divClassName="testimonials-instance"
                    divClassNameOverride="testimonials-instance"
                    ellipse="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ellipse-14-1@2x.png"
                    star="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/star-1-8.svg"
                    text="Như Trịnh"
                    text1="Cảm ơn Kiss House đã thổi cho Di Linh một làn gió mới; một khu cafe chill , một khu camping ấm cúng thân thiện với thiên nhiên. Chắc chắn tôi sẽ đưa bạn bè quay lại trong thời gian sớm nhất có thể 👍👍👍"
                  />
                  <Testimonials
                    divClassName="testimonials-instance"
                    divClassNameOverride="testimonials-instance"
                    ellipse="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ellipse-14@2x.png"
                    star="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/star-5-4.svg"
                    starClassName="testimonials-2"
                    text="Quốc Trần"
                    text1="Khu camping và cafe hấp dẫn, thoáng, mát mẻ, view cực đẹp"
                  />
                </div>
              </div>
              <img
                className="rectangle-2"
                alt="Rectangle"
                src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-31.svg"
              />
            </div>
            <div className="overlap-wrapper">
              <div className="overlap">
                <div className="overlap-group">
                  <img
                    className="vector"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/vector-5.svg"
                  />
                  <img
                    className="vector-2"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/vector-3.svg"
                  />
                  {/* Note hồng */}
                  {/* <img
                    className="pink-note"
                    alt="Pink note"
                    src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/pink-note.svg"
                  /> */}
                  <div className="pink-note">
                    <img
                      className="rectangle-11"
                      alt="Rectangle"
                      src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-49.svg"
                    />
                    <img
                      className="rectangle-10"
                      alt="Rectangle"
                      src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='564' height='729' viewBox='0 0 564 729' fill='none'%3E%3Cg filter='url(%23filter0_d_97_2052)'%3E%3Cpath d='M5 1L559 1V696.151C398.327 722.62 353.421 719.923 5 719.923L5 1Z' fill='%23FFDDED'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_97_2052' x='0' y='0' width='564' height='729' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='2.5'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_97_2052'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_97_2052' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E"
                    />
                    <div className="frame-50">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-3">
                          <div className="text-wrapper-31">Must have</div>
                          {/* <img
                            className="vector-4"
                            alt="Vector"
                            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQ1IiBoZWlnaHQ9IjYyIiB2aWV3Qm94PSIwIDAgMjQ1IDYyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC43NSA3LjVWNjJIMjI2LjI1TDI0NC4yNSAwTDUwLjc1IDcuNUgwLjc1WiIgZmlsbD0iI0ZGQTVEMCIvPgo8L3N2Zz4K"
                          /> */}
                        </div>
                      </div>
                      <div className="frame-51">
                        <Bullet
                          className="bullet-instance"
                          color="pink"
                          icon={<Frame963 className="frame-32" />}
                          override={<Bagpack className="icon-instance-node" />}
                          property1="default"
                          text="Quần áo"
                        />
                        <Bullet
                          className="bullet-instance"
                          color="pink"
                          icon={<Frame963 className="frame-32" />}
                          override={<Scarf1 className="icon-instance-node" />}
                          property1="default"
                          text="Đồ giữ ấm"
                        />
                        <Bullet
                          className="bullet-instance"
                          color="pink"
                          icon={<Frame963 className="frame-32" />}
                          override={
                            <PhotoCamera17 className="icon-instance-node" />
                          }
                          property1="default"
                          text="Máy chụp hình"
                        />
                        <Bullet
                          className="bullet-instance"
                          color="pink"
                          icon={<Frame963 className="frame-32" />}
                          override={
                            <CaptainCap1 className="icon-instance-node" />
                          }
                          property1="default"
                          text="Nón"
                        />
                        <Bullet
                          className="bullet-2"
                          color="pink"
                          icon={<Frame963 className="frame-32" />}
                          override={
                            <Sunblock1 className="icon-instance-node" />
                          }
                          property1="default"
                          text="Kem chống nắng"
                        />
                      </div>
                    </div>
                  </div>
                  {/* / Note Vàng / */}
                  <div className="yellow-note">
                    <div className="overlap-2">
                      <img
                        className="rectangle-3"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-49.svg"
                      />
                      <img
                        className="rectangle-4"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rectangle-48.svg"
                      />
                      <div className="frame-30">
                        <div className="overlap-group-wrapper">
                          <div className="overlap-group-2">
                            <div className="text-wrapper-14">Nice to have</div>
                            <img
                              className="vector-3"
                              alt="Vector"
                              src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/vector-10.svg"
                            />
                          </div>
                        </div>
                        <div className="frame-31">
                          {/* <Bullet
                            className="bullet-instance"
                            color="yellow"
                            // icon={<Frame968 className="frame-32" />}
                            property1="default"
                            text="Soffell chống muỗi"
                          /> */}
                          <Bullet
                            className="bullet-instance"
                            color="yellow"
                            icon={<Frame965 className="frame-32" />}
                            override={
                              <Ointment1 className="icon-instance-node" />
                            }
                            property1="default"
                            text="Soffell chống muỗi"
                          />
                          ;
                          <Bullet
                            className="bullet-2"
                            color="yellow"
                            icon={<Frame965 className="frame-32" />}
                            override={<X3138 className="icon-instance-node" />}
                            property1="default"
                            text="Kính mát"
                          />
                          <Bullet
                            className="bullet-3"
                            color="yellow"
                            icon={<Frame965 className="frame-32" />}
                            override={
                              <Primer1 className="icon-instance-node" />
                            }
                            property1="default"
                            text="Dưỡng ẩm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fujifilm-instax-wide">
                    <img
                      className="picture-fujifilm"
                      alt="Picture fujifilm"
                      src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/picture--fujifilm-instax-mini-format-.svg"
                    />
                  </div>
                  <div className="frame-33">
                    <div className="div-wrapper">
                      <div className="text-wrapper-15">Bucket list</div>
                    </div>
                    <p className="c-ng-i-m-danh-qua-nh">
                      Cùng điểm danh qua những món đồ bạn chuẩn bị khi tới{" "}
                      <br />
                      Kiss House nhé
                    </p>
                  </div>
                  <div className="fitness-tracker">
                    <img
                      className="fitness-tracker-2"
                      alt="Fitness tracker"
                      src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/fitness-tracker@2x.png"
                    />
                  </div>
                </div>
                <div className="polaroid-format">
                  <img
                    className="picture-polaroid"
                    alt="Picture polaroid"
                    src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/picture--polaroid-600-format.svg"
                  />
                </div>
                <div className="fujifilm-instax-mini">
                  <img
                    className="picture-fujifilm-2"
                    alt="Picture fujifilm"
                    src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/picture--fujifilm-instax-mini-format.svg"
                  />
                </div>
              </div>
            </div>
            <div ref={FAQRef} className="frame-34">
              <p className="text-wrapper-16">Những câu hỏi thường gặp</p>
              <div className="frame-35">
                <Accordians
                  className="design-component-instance-node-2"
                  property1="default"
                  text="Ở đây có nước máy nóng lạnh không?"
                  text2="Được trang bị đầy đủ nước nóng/lạnh"
                />
                <Accordians
                  className="design-component-instance-node-2"
                  property1="default"
                  text="Trong lều có tiện nghi gì?"
                  text2="Trong lều có đầy đủ: vật dụng cá nhân, khăn tắm, quạt máy, chăn dày ấm, nệm êm không sợ lạnh và ướt khi trời mưa"
                />
                <Accordians
                  className="design-component-instance-node-2"
                  property1="default"
                  text="Kiss House có gần trung tâm thị trấn Di Linh không?"
                  text2="Kiss House nằm ngay trung tâm Hồ Tây - Thị trấn Di Linh, cách quốc lộ QL20 chỉ 3km."
                />
                <Accordians
                  className="design-component-instance-node-2"
                  property1="default"
                  text="Đường vào Kiss House có khó không?"
                  text2="Dễ dàng, xe máy hay ô tô lẫn xe khách 29 chỗ đều có thể vào được"
                />
              </div>
            </div>
            {/* </Fade> */}
            <div className="overlap-4">
          <div className="frame-36">
            <div ref={MapRef} className="interact-box">
              <div
                class="fb-page"
                data-href="https://www.facebook.com/KissHouseDiLinh/"
                data-tabs=""
                data-width=""
                data-height=""
                data-small-header="false"
                data-adapt-container-width="true"
                data-hide-cover="false"
                data-show-facepile="true"
              >
                <blockquote
                  cite="https://www.facebook.com/KissHouseDiLinh/"
                  class="fb-xfbml-parse-ignore"
                >
                  {/* <a href="https://www.facebook.com/KissHouseDiLinh/">
                    Kiss House Coffee and Glamping
                  </a> */}
                </blockquote>
              </div>
              <div style={{ height: '300px', width: '800px' }}>
                {/* <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBbFLs0KfmOVlycoM7AFeSWdqUS-BPsQ2A" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}>
              </GoogleMapReact> */}
                <Iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.59930643654!2d108.04978211022132!3d11.580556243800402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3171517be779ac43%3A0x888c5c05ee6502b4!2sKiss%20House%20Coffee%20and%20Glamping!5e0!3m2!1svi!2s!4v1696233169859!5m2!1svi!2s"
                  width="800"
                  height="300"
                  style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade">
                </Iframe>
              </div>
            </div>
            <footer className="footer">
              <div className="frame-37">
                <div className="frame-38">
                  <div className="frame-39">
                    <Logo
                      className="logo-instance"
                      logo="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/logo-1.svg"
                    // logo="https://c.animaapp.com/ZJsv2hm4/img/kisshouse-transparent.png"
                    />
                    <p className="text-wrapper-21">
                      Kiss House Coffee and Glamping
                    </p>
                    <div className="text-wrapper-22">
                      Nơi để Kết nối - Yêu thương - Sẻ chia
                    </div>
                  </div>
                </div>
                <div className="frame-40">
                  <div className="frame-41">
                    <div className="text-wrapper-23">Địa chỉ</div>
                    <p className="text-wrapper-24">
                      55/8 Ngô Quyền, thị trấn Di Linh, huyện Di linh, tỉnh Lâm
                      Đồng
                    </p>
                  </div>
                  <div className="frame-42">
                    <div className="text-wrapper-23">Liên hệ</div>
                    <div className="text-wrapper-25">Hotline: 0971 900 558</div>
                  </div>
                  <div className="frame-42">
                    <p className="text-wrapper-23">Kết nối với chúng tôi</p>
                    <div className="frame-43">
                      <a href="https://www.facebook.com/KissHouseDiLinh/">
                        <FacebookNegative className="arrow-forward-2" />
                      </a>
                      <TiktokNegative className="arrow-forward-2" />
                      <a href="http://zaloapp.com/qr/p/1h5giqovoe9x7">
                        <img
                          className="arrow-forward-2"
                          width="100"
                          height="100"
                          href=""
                          src="https://i.pinimg.com/originals/82/53/22/82532235b1c99e391706a9c6e396aeaa.jpg"
                          alt="zalo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
          </div>
          <div className="overlap-3">
            <div className="slide-header">
              <Fade
                nextArrow={
                  <button
                    style={{
                      backgroundSize: "cover",
                      border: "0px",
                      width: "0px",
                      position: "fixed",
                    }}
                  >
                    {/* <svg
                    fill="#fff"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
                  </svg> */}
                  </button>
                }
                prevArrow={
                  <button
                    style={{
                      backgroundSize: "cover",
                      border: "0px",
                      width: "0px",
                      position: "fixed",
                    }}
                  >
                    {/* <svg
                    fill="#fff"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
                  </svg> */}
                  </button>
                }
              >
                <div className="each-slide">
                  <div>
                    <img src={images[0]} />
                  </div>
                </div>
                <div className="each-slide">
                  <div>
                    <img src={images[1]} />
                  </div>
                </div>
                <div className="each-slide">
                  <div>
                    <img src={images[2]} />
                  </div>
                </div>
                <div className="each-slide">
                  <div>
                    <img src={images[3]} />
                  </div>
                </div>
              </Fade>
            </div>
            {/* <div className="section-3">
              <p className="ch-o-m-ng-b-n-n-t-i">
                Chào mừng bạn đến với <br />
                Kiss House Coffee and Glamping{" "}
              </p>
              <p1 className="subtitle">
                Nơi để kết nối - Yêu thương - Sẻ chia
              </p1>
            </div> */}
            <div className="frame-49">
              <div className="div">
                <div className="div-2">
                  <p className="text-wrapper">KISS HOUSE COFFEE AND GLAMPING</p>
                  <p className="p">Kết Nối Yêu Thương - Sẻ Chia Cuộc Sống</p>
                </div>
                <div className="div-3">
                  <p className="text-wrapper-2">
                    Đến với Kiss House bạn sẽ cảm nhận được cái mát lạnh của cao nguyên, đắm chìm vào không gian trong lành và
                    thư thái giúp tâm hồn bạn trở nên nhẹ nhàng, reset lại năng lượng cho những kế hoạch tương lai và hơn cả là
                    những yêu thương được đâm chồi.
                  </p>
                  <p className="text-wrapper-3">
                    Tọa lạc giữa thị trấn Di Linh, bên cạnh hồ Tây đầy thơ mộng. Kiss House mang trong mình vẻ đẹp nhẹ nhàng,
                    đầy tao nhã khi sở hữu tầm view ôm trọn hồ, phía xa xa là những đồi cafe xanh mát tất cả như một bức tranh
                    thủy mạc ngoài đời thực.
                  </p>
                </div>
              </div>
            </div>
            <div className="header">
              <div className="header-nav">
                <Logo
                  className="logo-instance"
                  // logo="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/logo-1.svg"
                  logo="https://c.animaapp.com/ZJsv2hm4/img/kisshouse-transparent.png"
                />
                <div className="frame-16">
                  <div className="menu">
                    <button onClick={serviceClick} className="text-wrapper-20">
                      Dịch vụ
                    </button>
                  </div>
                  <div className="menu">
                    <button onClick={faqClick} className="text-wrapper-20">
                      FAQ
                    </button>
                  </div>
                  <div className="menu">
                    <button onClick={activityClick} className="text-wrapper-20">
                      Khác
                    </button>
                  </div>
                  <div className="menu">
                    {/* <div className="text-wrapper-20">Hotline: 0971 900 558</div> */}
                    <button onClick={mapClick} className="text-wrapper-20">
                      Vị trí
                    </button>
                    {/* <button onClick={priceClick} className="text-wrapper-20">
                      Bảng giá Camping
                    </button> */}
                  </div>
                  <div className="rectangle-9" />
                  <div className="menu">
                    <button onClick={priceClick} className="text-wrapper-18-1">
                      Bảng giá Camping
                    </button>
                  </div>
                  <div className="menu">
                    <img className="call" alt="Call" src="https://c.animaapp.com/bXgKqYAU/img/call@2x.png" />
                    <div className="text-wrapper-18-1">0971 900 558</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <a href="http://zaloapp.com/qr/p/1h5giqovoe9x7">
          <img
            className="zalo-icon"
            width="48"
            height="48"
            src="https://img.icons8.com/color/48/zalo.png"
            alt="zalo"
          />
        </a>
      </div>
    </Resizable>
  );
};
