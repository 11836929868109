/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const SocialIcons1 = ({ className }) => {
  return (
    <svg
      className={`social-icons-1 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_57_3466)">
        <path
          className="path"
          d="M6.99988 5.72705V8.43796H10.7671C10.6017 9.30978 10.1053 10.048 9.36076 10.5443L11.6326 12.3071C12.9562 11.0853 13.7198 9.29075 13.7198 7.15894C13.7198 6.66258 13.6753 6.18527 13.5926 5.72713L6.99988 5.72705Z"
          fill="#4285F4"
        />
        <path
          className="path"
          d="M3.0769 8.33252L2.56452 8.72474L0.750854 10.1374C1.90267 12.422 4.2634 14.0002 6.99974 14.0002C8.8897 14.0002 10.4742 13.3765 11.6324 12.3075L9.36062 10.5447C8.73698 10.9647 7.94152 11.2193 6.99974 11.2193C5.17976 11.2193 3.63344 9.99112 3.07976 8.33656L3.0769 8.33252Z"
          fill="#34A853"
        />
        <path
          className="path"
          d="M0.750854 3.86279C0.273608 4.80457 0 5.86731 0 7.00002C0 8.13273 0.273608 9.19548 0.750854 10.1373C0.750854 10.1436 3.07999 8.32998 3.07999 8.32998C2.93999 7.90998 2.85724 7.46456 2.85724 6.99995C2.85724 6.53534 2.93999 6.08992 3.07999 5.66992L0.750854 3.86279Z"
          fill="#FBBC05"
        />
        <path
          className="path"
          d="M6.99989 2.78727C8.03081 2.78727 8.94716 3.14362 9.67898 3.83091L11.6835 1.82639C10.468 0.69368 8.88992 0 6.99989 0C4.26355 0 1.90267 1.57182 0.750854 3.86273L3.07991 5.67001C3.63352 4.01544 5.1799 2.78727 6.99989 2.78727Z"
          fill="#EA4335"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_57_3466">
          <rect className="rect" fill="white" height="14" width="14" />
        </clipPath>
      </defs>
    </svg>
  );
};
