/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Camp1 = ({ className }) => {
  return (
    <svg
      className={`camp-1 ${className}`}
      fill="none"
      height="90"
      viewBox="0 0 90 90"
      width="90"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M31.3691 43.6773C28.9867 49.2361 28.1926 58.6332 28.5897 64.7214C28.5897 65.2508 29.1191 65.6479 29.5162 65.6479C44.2073 65.6479 58.6338 63.3979 72.9279 61.942C73.1926 61.942 73.4573 61.6773 73.722 61.545C74.1191 61.0156 73.722 60.4861 73.5897 60.0891C69.7515 49.6332 68.0309 47.3832 67.7662 46.8538C65.3838 42.4861 62.8691 38.1185 59.4279 34.8097C48.972 25.0155 37.0603 30.1773 31.3691 43.6773ZM30.575 63.6626C30.4426 59.8244 30.7073 54.6626 31.6338 50.295L55.5897 48.045C55.8544 48.045 55.9867 48.045 56.2515 48.1773C61.1485 51.4861 66.1779 56.1185 70.6779 60.3538C57.1779 61.6773 43.9426 63.5303 30.575 63.6626ZM66.3103 48.1773C68.0309 51.3538 69.3544 54.2656 70.5456 57.442C66.3103 53.6038 61.8103 49.6332 57.3103 46.4567L57.1779 46.3244L57.3103 35.4714C61.2809 38.7803 63.7956 43.545 66.3103 48.1773ZM55.4573 34.1479L55.1926 46.0597L32.0309 48.3097C32.4279 46.9861 32.825 45.6626 33.222 44.6038C35.472 39.045 40.2367 32.8244 46.4573 31.8979C49.6338 31.3685 52.8103 32.4273 55.4573 34.1479Z"
        fill="#D3FF9A"
      />
      <path
        className="path"
        d="M62.9988 12.1769L61.94 16.0152C61.8076 16.5446 62.0723 17.074 62.6017 17.2064C63.1312 17.3387 63.6606 17.074 63.7929 16.5446L64.8517 12.7064C64.9841 12.1769 64.7194 11.6475 64.19 11.5152C63.6606 11.3828 63.1312 11.6475 62.9988 12.1769Z"
        fill="#D3FF9A"
      />
      <path
        className="path"
        d="M70.9416 12.8388C70.5445 12.4418 69.8827 12.4418 69.4857 12.8388C68.1622 14.2947 66.971 15.8829 66.1769 17.6035C65.9122 18.1329 66.1769 18.6623 66.5739 18.927C67.1033 19.1918 67.6327 18.927 67.8975 18.53C68.6916 16.9418 69.7504 15.4859 70.9416 14.2947C71.471 13.8976 71.3386 13.2359 70.9416 12.8388Z"
        fill="#D3FF9A"
      />
      <path
        className="path"
        d="M70.0137 23.0295C71.3372 22.7648 72.5284 22.2354 73.7196 21.5736C74.249 21.3089 74.3814 20.6472 74.1166 20.2501C73.8519 19.7207 73.1902 19.5884 72.7931 19.8531C71.8666 20.3825 70.6755 20.7795 69.6166 21.0442C69.0872 21.1766 68.6902 21.706 68.8225 22.2354C68.9549 22.8972 69.4843 23.1619 70.0137 23.0295Z"
        fill="#D3FF9A"
      />
      <path
        className="path"
        d="M2.38208 56.1178C2.91149 56.2502 3.4409 55.9855 3.57325 55.4561C3.57325 55.3237 3.70561 55.1914 3.70561 55.1914C4.10266 56.5149 3.30855 53.6031 7.41149 66.1767C8.47031 69.3531 9.26443 72.6619 10.7203 75.8384C10.588 76.2355 10.4556 76.7649 10.4556 77.2943C10.3233 77.8237 10.7203 78.3531 11.2497 78.4855H11.5144C11.9115 78.4855 12.4409 78.2208 12.4409 77.6914C12.4409 77.4267 12.5733 77.1619 12.7056 76.8972C37.3233 74.3825 62.0733 72.0002 86.6909 69.4855C86.9556 70.0149 87.485 70.1472 87.8821 70.0149C88.4115 69.8825 88.6762 69.2208 88.4115 68.6914C82.7203 53.8678 75.0438 40.2355 65.6468 28.3237C60.485 21.7061 53.9997 15.0884 45.6615 14.8237C45.5291 14.8237 45.3968 14.6914 45.2644 14.6914C39.9703 14.1619 33.7497 16.1472 28.7203 18.7943C17.2056 24.2208 9.92619 34.9414 4.89678 46.7208C3.97031 48.7061 3.04384 50.6914 2.11737 52.6766C1.72031 53.3384 1.45561 55.059 1.85266 55.7208C1.98502 55.9855 2.24972 56.1178 2.38208 56.1178ZM5.29384 51.7502C5.29384 51.6178 5.16149 51.6178 5.16149 51.4855C9.52914 40.2355 16.5438 30.4414 25.2791 23.4267C27.1321 21.9708 29.2497 20.7796 31.4997 19.7208C31.7644 20.2502 32.2938 21.1767 32.9556 21.9708C31.7644 23.559 30.7056 25.2796 29.7791 27.0002C21.9703 40.5002 16.1468 55.1914 12.1762 70.6767C9.52914 62.7355 6.35266 54.3972 5.29384 51.7502ZM34.0144 20.2502C33.6174 19.7208 33.3527 19.1914 33.088 18.7943C34.2791 18.2649 35.4703 17.8678 36.6615 17.6031C35.735 18.3972 34.9409 19.3237 34.0144 20.2502ZM64.0586 29.5149C72.6615 40.5002 79.8085 52.9414 85.3674 66.4414L41.0291 70.5443C12.5733 73.1914 16.8085 74.2502 13.235 73.9855C17.2056 57.5737 23.2938 41.9561 31.3674 27.7943C32.2938 26.2061 33.2203 24.6178 34.4115 23.0296C41.9556 26.0737 51.8821 24.7502 56.5144 21.4414C59.4262 23.8237 61.9409 26.8678 64.0586 29.5149ZM54.7938 20.2502C50.6909 22.8972 42.2203 23.9561 35.6027 21.5737C36.6615 20.3825 37.8527 19.1914 39.1762 18.3972C43.1468 16.0149 48.838 16.0149 54.7938 20.2502Z"
        fill="#D3FF9A"
      />
    </svg>
  );
};
