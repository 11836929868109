/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Frame961 } from "../../icons/Frame961";
import { PhotoCamera17 } from "../../icons/PhotoCamera17";
import "./style.css";

export const Bullet = ({
  property1,
  color,
  className,
  icon = <Frame961 className="frame-96-1" />,
  text = "Máy chụp hình",
  override = <PhotoCamera17 className="photo-camera" />,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
    color: color || "pink",
  });

  return (
    <div
      className={`bullet ${state.color} property-1-5-${state.property1} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onClick={() => {
        dispatch("click");
      }}
    >
      <div className="frame-8">
        {icon}
        <div className="m-y-ch-p-h-nh">{text}</div>
      </div>
      {override}
    </div>
  );
};

function reducer(state, action) {
  if (state.color === "pink" && state.property1 === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "pink",
          property1: "hover",
        };
    }
  }

  if (state.color === "pink" && state.property1 === "hover") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "pink",
          property1: "default",
        };

      case "click":
        return {
          color: "pink",
          property1: "active",
        };
    }
  }

  if (state.color === "yellow" && state.property1 === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          color: "yellow",
          property1: "hover",
        };
    }
  }

  if (state.color === "yellow" && state.property1 === "hover") {
    switch (action) {
      case "mouse_leave":
        return {
          color: "yellow",
          property1: "default",
        };

      case "click":
        return {
          color: "yellow",
          property1: "active",
        };
    }
  }

  if (state.color === "pink" && state.property1 === "active") {
    switch (action) {
      case "click":
        return {
          color: "pink",
          property1: "default",
        };
    }
  }

  if (state.color === "yellow" && state.property1 === "active") {
    switch (action) {
      case "click":
        return {
          color: "yellow",
          property1: "default",
        };
    }
  }

  return state;
}

Bullet.propTypes = {
  property1: PropTypes.oneOf(["hover", "active", "default"]),
  color: PropTypes.oneOf(["yellow", "pink"]),
  text: PropTypes.string,
};
