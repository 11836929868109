/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Frame = ({ property1, className, text = "Lều gia đình nội khu", text1 = "1.000.000 VND" }) => {
  return (
    <div className={`frame property-1-3-${property1} ${className}`}>
      <p className="tittle">{text}</p>
      <div className="div-2">
        <div className="element-VND">{text1}</div>
        <div className="text-wrapper-3">/đêm/lều</div>
      </div>
      <p className="text-wrapper">Đã bao gồm ăn sáng và cafe sáng</p>
    </div>
  );
};

Frame.propTypes = {
  property1: PropTypes.oneOf(["hover", "default"]),
  text: PropTypes.string,
  text1: PropTypes.string,
};
