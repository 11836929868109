/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Frame96 = ({ className }) => {
  return (
    <svg
      className={`frame-96 ${className}`}
      fill="none"
      height="35"
      viewBox="0 0 34 35"
      width="34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" filter="url(#filter0_d_95_521)">
        <rect
          className="rect"
          height="29.5625"
          shapeRendering="crispEdges"
          stroke="black"
          strokeLinecap="round"
          strokeWidth="2.0625"
          width="29.5625"
          x="1.03125"
          y="1.40625"
        />
      </g>
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="33.6875"
          id="filter0_d_95_521"
          width="33.6875"
          x="0"
          y="0.375"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dx="2.0625" dy="2.0625" />
          <feComposite className="fe-composite" in2="hardAlpha" operator="out" />
          <feColorMatrix className="fe-color-matrix" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_95_521" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_95_521"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
