/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Accordians = ({
  property1,
  className,
  text = "Accordians",
  text2,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className={`accordians ${className}`}
      onClick={() => {
        dispatch("click");
      }}
    >
      <div className={`div-3 property-1-3-${state.property1}`}>
        {state.property1 === "default" && <>{text}</>}

        {state.property1 === "variant-2" && (
          <>
            <div className="text-wrapper-5">{text}</div>
            <div className="text-wrapper-6">{text2}</div>
          </>
        )}
      </div>
      <img
        className="add"
        alt="Add"
        src={
          state.property1 === "variant-2"
            ? "https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/remove-1@2x.png"
            : "https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/add-5@2x.png"
        }
      />
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "default") {
    switch (action) {
      case "click":
        return {
          property1: "variant-2",
        };
    }
  }

  if (state.property1 === "variant-2") {
    switch (action) {
      case "click":
        return {
          property1: "default",
        };
    }
  }

  return state;
}

Accordians.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  text: PropTypes.string,
  text2: PropTypes.string,
};
