/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { SocialIcons1 } from "../../icons/SocialIcons1";
import "./style.css";

export const Testimonials = ({
  text = "Our team",
  divClassName,
  divClassNameOverride,
  text1 = "We had such an amazing time. What a beautiful surprise stop while exploring Vietnam. The BBQ was such a highlight and the service was great. Everyone was so helpful.",
  ellipse = "https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ellipse-14-8@2x.png",
  starClassName,
  star = "https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/star-1-8.svg",
  divClassName1,
}) => {
  return (
    <div className="testimonials">
      <div className="frame-4">
        <img
          className="img"
          alt="Img"
          src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/--8.svg"
        />
        <p className="we-had-such-an">{text1}</p>
      </div>
      <div className="frame-5">
        <img className="ellipse" alt="Ellipse" src={ellipse} />
        <div className="content">
          <div className={`our-team ${divClassName1}`}>{text}</div>
          <div className="frame-6">
            <div className="star">
              <img
                className="star-2"
                alt="Star"
                src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/star-1-8.svg"
              />
              <img
                className="star-2"
                alt="Star"
                src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/star-1-8.svg"
              />
              <img
                className="star-2"
                alt="Star"
                src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/star-1-8.svg"
              />
              <img
                className="star-2"
                alt="Star"
                src="https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/star-1-8.svg"
              />
              <img className={`star-2 ${starClassName}`} alt="Star" src={star} />
            </div>
            <div className="frame-7">
              <div className={`text-wrapper-4 ${divClassName}`}>on</div>
              <SocialIcons1 className="social-icons" />
              <div className={`text-wrapper-4 ${divClassNameOverride}`}>Google</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Testimonials.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  ellipse: PropTypes.string,
  star: PropTypes.string,
};
